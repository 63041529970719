import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f815f29a = () => interopDefault(import('../pages/books/index.vue' /* webpackChunkName: "pages/books/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _9800e10e = () => interopDefault(import('../pages/people.vue' /* webpackChunkName: "pages/people" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _fb83352a = () => interopDefault(import('../pages/books/_slug.vue' /* webpackChunkName: "pages/books/_slug" */))
const _565cf77e = () => interopDefault(import('../pages/categories/_slug.vue' /* webpackChunkName: "pages/categories/_slug" */))
const _29fc418c = () => interopDefault(import('../pages/person/_slug.vue' /* webpackChunkName: "pages/person/_slug" */))
const _6b891f54 = () => interopDefault(import('../pages/posts/_slug.vue' /* webpackChunkName: "pages/posts/_slug" */))
const _4bbf8048 = () => interopDefault(import('../pages/series/_slug.vue' /* webpackChunkName: "pages/series/_slug" */))
const _e203d6b4 = () => interopDefault(import('../pages/timeline/_slug.vue' /* webpackChunkName: "pages/timeline/_slug" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/books",
    component: _f815f29a,
    name: "books"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/people",
    component: _9800e10e,
    name: "people"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register"
  }, {
    path: "/books/:slug",
    component: _fb83352a,
    name: "books-slug"
  }, {
    path: "/categories/:slug?",
    component: _565cf77e,
    name: "categories-slug"
  }, {
    path: "/person/:slug?",
    component: _29fc418c,
    name: "person-slug"
  }, {
    path: "/posts/:slug?",
    component: _6b891f54,
    name: "posts-slug"
  }, {
    path: "/series/:slug?",
    component: _4bbf8048,
    name: "series-slug"
  }, {
    path: "/timeline/:slug?",
    component: _e203d6b4,
    name: "timeline-slug"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
