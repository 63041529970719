

export default function(to, from, savedPosition) {
      if (to.hash) {
        setTimeout(() => {
          window.scrollTo({
            top: document.querySelector(to.hash).offsetTop,
            behavior: 'smooth'
          });
        },100);
      }

      return window.scrollTo({ top: 0, behavior: 'smooth' });
    }
