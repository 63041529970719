<template>
  <div class="footer">
    <div class="copyright">Content Copyright &copy; Joshua Jung, 2021</div>
  </div>
</template>

<script>
  import Links from "~/components/Links";
  import './Footer.scss';

  export default {
    components: {
      Links
    }
  };
</script>
